import { buildFonts, fontlist } from '@monobits/fonts'
import { makeFluids } from '@monobits/scales'

const fluid = makeFluids(
  {
    'size-small': ['14px', '16px', false],
    'size-text': ['16px', '22px'],
    'size-caption': ['18px', '28px'],
    'size-title': ['26px', '88px'],
    'size-emphasized': ['60px', '200px'],
  },
  ['375px', '2500px']
)

export const scales = {
  fontSizes: fluid.scale,
  fontWeights: {
    body: 400,
    heading: 400,
  },
  lineHeights: {
    body: 1.35,
    heading: 1.1,
  },
}

export const rootFontStyles = {
  fontSize: 1,
  fontFamily: 'neueHaas',
  lineHeight: 'body',
  fontWeight: 'body',

  '.-rte + .-rte': { mt: '1em' },
  '.-italic': {
    fontStyle: 'italic',
  },

  ...fluid.properties,
}

const common = (type = 'body') => ({
  fontFamily: type,
  fontWeight: type,
  lineHeight: type,
  crops: 'neueHaas',
})

export default buildFonts(
  [
    fontlist(['neueHaas'], {
      neueHaas: {
        family: 'Neue Haas Grotesk',
        fallbacks: 'helvetica, arial, sans-serif',
        crop: [7, 10],
      },
    }),
    scales,
  ],
  {
    fonts: {
      heading: 'inherit',
      body: 'inherit',
      monospace: 'Menlo, monospace',
    },
    variants: {
      key: 'text',
      heading: { variant: 'text.title' },
      __default: { variant: 'text.text' },
      __cropped: {
        emphasized: {
          fontSize: 4,
          ...common('heading'),
        },
        title: {
          fontSize: 3,
          ...common('heading'),
        },
        caption: {
          fontSize: 2,
          ...common(),
        },
        text: {
          fontSize: 1,
          ...common(),
        },
        small: {
          fontSize: 'var(--size-small, 12px)',
          ...common(),
        },
      },
    },
  }
)
