import { Icon } from '@monobits/components'
import ScrollTo from '../ScrollTo'

const Logo = ({ icon, sx, ...props }) => (
  <ScrollTo anchor="welcome" sx={{ height: 'calc(var(--header-height) - (var(--padding-x) * 2))', ...sx }}>
    <Icon icon={icon} sx={{ width: 'unset', ...sx }} {...props} />
  </ScrollTo>
)

export default Logo
