import { merge } from 'theme-ui'
import { preset } from '@monobits/preset-base'
import { table } from '@monobits/preset-variants'
import { locomotive } from '@monobits/styles'
import { omit } from 'lodash'
import { easeInOut } from 'polished'

import config from './config'
import colors from './colors'
import overrides from './overrides'
import fonts, { rootFontStyles } from './fonts'
import scales, { rootScaleStyles } from './scales'
import styles from './styles'
import gsap from './gsap'
import * as variants from './variants'

export default merge(omit(preset, ['colors']), {
  config,
  colors,

  breakpoints: ['43em', '62em', '82em'],

  styles: {
    root: {
      ...styles,
      ...rootFontStyles,
      ...rootScaleStyles,
      ...merge(locomotive, {
        '.c-scrollbar': {
          color: 'dark',
        },
      }),

      body: {
        bg: 'background',
        '.c-scrollbar': { zIndex: 'scrollbar' },
      },
    },
  },
  ...fonts,
  ...scales,
  ...variants,
  ...overrides,
  table,
  gsap,

  motion: {
    collapsible: {
      transition: {
        duration: 0.4,
        ease: 'easeInOutCubic',
      },
      bezier: easeInOut('cubic'),
    },
  },
})
