export default {
  head: {
    display: 'flex',
    alignItems: 'flex-end',

    '--height': 65,
    minHeight: 'calc((var(--height, 100) * 1vh) - var(--offset, 0px))',

    grid: {
      display: 'grid',
      alignItems: 'end',
      gridGap: '2em',

      gridTemplateColumns: ['1fr', null, '1fr auto'],
    },
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    pt: [2, null, 3],
    pb: 1,
    px: 1,

    '--height': 100,
    '--offset': 'calc(var(--header-height) - 1px)',

    content: {
      gridTemplateColumns: ['1fr', null, 'repeat(2, 1fr)'],
      gridGap: [1, null, 2],

      alignItems: 'end',
    },
    '.form': {
      mt: 1,
    },
    '.form, .buttons': { maxWidth: [null, null, 800] },
    '.buttons': {
      justifySelf: 'end',
      width: '100%',
      gridTemplateColumns: [
        '1fr',
        'repeat(2, 1fr)',
        'var(--button-group-columns)',
        //
      ],
    },
  },
  section: {
    regular: { my: [2, null, 3] },
  },
}
