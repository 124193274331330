import styled from '@emotion/styled'
import { css } from 'theme-ui'

const x = {
  outerHeight: 'var(--header-height)',
  speed: 'var(--speed, 0.45s)',
  ease: 'var(--ease, ease)',
}

const stickyStyles = (use) =>
  use && {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 'nav',
  }

const shyStyles = (use) =>
  use && {
    '[data-direction="down"] &': {
      '--translate': `calc(${x.outerHeight} * -1)`,
    },
    '[data-scroll-position="top"] &,[data-scroll-position="bottom"] &': {
      '--translate': '0.0001px',
    },
    transform: 'translate3d(0, var(--translate, 0.0001px), 0)',
    transition: `transform ${x.speed} ${x.ease}`,
    willChange: 'transform',
  }

export const StyledHeader = styled.header(({ sticky, shy }) =>
  css({
    display: 'flex',
    height: 'var(--header-height)',
    width: '100%',

    '>div': {
      width: '100%',
      display: 'flex',
    },

    ...stickyStyles(sticky),
    ...shyStyles(sticky && shy),
  })
)
