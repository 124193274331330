import { useRef } from 'react'
import { RecoilRoot } from 'recoil'
import { useThemeUI, Box } from 'theme-ui'
import { Helmet } from 'react-helmet-async'
import { LocomotiveScrollProvider } from '@monobits/locomotive-scroll'
import { ColorModeTransition } from '@monobits/color-transitions'
import { Sizes } from '@monobits/components'

import Menu from '../components/Menu'

const Layout = ({ children, location }) => {
  const ref = useRef(null)
  const { locomotive } = useThemeUI(null).theme?.config ?? {}

  return (
    <RecoilRoot>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0, shrink-to-fit=no"
        />
      </Helmet>

      <ColorModeTransition />
      <Sizes callbacks={location} />

      <LocomotiveScrollProvider
        options={locomotive}
        containerRef={ref}
        location={location}
        onLocation={({ scroll }) => scroll.scrollTo(0, { duration: 0, disableLerp: true })}
      >
        <Menu sticky />
        <Box as="main" ref={ref} data-scroll-container>
          {children}
        </Box>
      </LocomotiveScrollProvider>
    </RecoilRoot>
  )
}

export default Layout
