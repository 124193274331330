import { makeFluids } from '@monobits/scales'

const fluid = makeFluids(
  {
    'padding-x': ['20px', '40px'],
    'padding-m': ['40px', '80px'],
    'padding-l': ['80px', '160px'],
    'header-height': ['82px', '156px', false],
  },
  ['375px', '2500px']
)

const buttonProperty = (prop = 'fill', value = 'var(--button-width)') => `repeat(auto-${prop}, minmax(${value}, 1fr))`

export const rootScaleStyles = {
  ...fluid.properties,
  '--stroke-x': '1px',
  '--stroke-s': '1px',
  '--stroke-m': '2px',
  '--button-width': '16rem',
  '--button-px': '1.2em',
  '--button-py': '0.9em',
  '--main-gap': '1.25em',
  '--icon-width': '1.5em',
  '--button-group-columns': buttonProperty('fill'),
  '--button-group-columns-fit': buttonProperty('fit'),
  '--button-group-columns-semi': ['1fr', null, buttonProperty('fill', '12rem')],
}

export default {
  space: fluid.scale,
  borderWidths: [0, 1, 2],
  zIndices: {
    below: -1,
    default: 1,
    nav: 10,
    popup: 20,
    panel: 30,
    modal: 40,
    scrollbar: 50,
  },
}
