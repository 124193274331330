import { useState } from 'react'
import { useRecoilValue } from 'recoil'
import { useUpdateEffect } from '@react-hookz/web'
import { useLocomotiveScroll } from '@monobits/locomotive-scroll'
import { startCase } from 'lodash'

import { pageAnchors } from '../atoms'
import ScrollTo from './ScrollTo'

const Indicator = ({ inview, handle, ...props }) => (
  <ScrollTo
    sx={{ variant: 'indicator.bar' }}
    title={startCase(handle)}
    aria-label={startCase(handle)}
    data-inview={!!(handle === inview)}
    anchor={handle}
    {...props}
  />
)

const ScrollNav = () => {
  const { scroll } = useLocomotiveScroll()
  const source = useRecoilValue(pageAnchors)
  const [inview, setInview] = useState(source[0])

  useUpdateEffect(() => {
    scroll.on('call', (func, event) => event === 'enter' && setInview(func))
  }, [scroll])

  return (
    <aside sx={{ variant: 'indicator' }}>
      <div>{source && source.map((key, i) => <Indicator key={key + i} inview={inview} handle={key} />)}</div>
    </aside>
  )
}

export default ScrollNav
