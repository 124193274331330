export default {
  __default: {
    display: 'grid',
    gridGap: [2, null, 3],

    '.-content': {
      gridGap: '2em',
    },
    '.-buttons': {
      gridTemplateColumns: 'repeat(auto-fill, minmax(var(--button-width), 1fr))',
      gridGap: 1,
    },
    // '.-caption': {},
  },
  content: {
    gridGap: 2,
    gridTemplateColumns: ['1fr', null, '1fr 1fr'],
  },
}
