import Obfuscate from 'react-obfuscate'

import Logo from './Logo'
import icon from '../../../assets/svg/logo-short.svg'

import IconButton from '../IconButton'
import iconPhone from '../../../assets/svg/phone.svg'
import iconMail from '../../../assets/svg/mail.svg'

const Touch = ({ data, sx, ...props }) => {
  const {
    contact: { email, phone },
  } = data ?? {}

  return (
    <>
      <Logo icon={icon} />
      <nav sx={{ variant: 'menu.nav.touch', ...sx }} {...props}>
        <Obfuscate {...phone} target="_blank" aria-label="Call us" children={<IconButton icon={iconPhone} />} />
        <Obfuscate {...email} target="_blank" aria-label="Email us" children={<IconButton icon={iconMail} />} />
      </nav>
    </>
  )
}

export default Touch
