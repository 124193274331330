const mediaClasses = (display, { prepend = true } = {}) => {
  const pre = !prepend ? '' : '-' + display
  return {
    ['.-hide-mobile' + pre]: { display: ['none', display] },
    ['.-hide-tablet' + pre]: { display: [null, 'none', display] },
    ['.-hide-desktop' + pre]: { display: [null, null, 'none'] },
    ['.-show-mobile' + pre]: { display: [null, 'none'] },
    ['.-show-tablet' + pre]: { display: ['none', display, 'none'] },
    ['.-show-desktop' + pre]: { display: ['none', null, display] },
  }
}

export default {
  ...mediaClasses('grid'),
  ...mediaClasses('block'),
  ...mediaClasses('flex', { prepend: false }),

  '.-line': {
    '--stroke': 'var(--stroke-x, 1px)',
    // overflow: 'clip',

    '::after': {
      content: '""',
      display: 'flex',
      pointerEvents: 'none',

      bg: 'text',
      opacity: 0.2,

      width: 'var(--stroke)',

      position: 'absolute',
      left: 'calc(50% - (var(--stroke) / 2))',
      bottom: '0',
      top: '0',
    },
  },
}
