export default {
  header: {
    __default: {
      p: 1,
      bg: 'background',

      opacity: 0,
      transform: 'translateY(-101%)',

      '>div': {
        justifyContent: 'space-between',
        alignItems: 'center',
      },

      '@media (hover: none)': {
        '.-mouse': { display: 'none' },
      },
      '@media (hover: hover)': {
        '.-touch': { display: 'none' },
      },
      '@media (max-width: 61.95em)': {
        '.-mouse': { display: 'none' },
        '.-touch': { display: 'flex' },
      },
    },
  },
  nav: {
    __default: {
      display: 'grid',
      gridAutoFlow: 'column',
      alignItems: 'center',
    },
    mouse: {
      variant: 'menu.nav',
      gridGap: '4em',
    },
    touch: {
      variant: 'menu.nav',
      gridGap: '1em',
    },
  },
}
