export default {
  __default: {
    fontSize: 1,
    width: '2.75em',
    height: '2em',
    display: 'flex',
    justifyContent: 'flex-end',

    '&>div': {
      display: 'grid',
      height: 'fit-content',
    },
  },
  bar: {
    display: 'flex',
    width: '1.75em',
    height: 'var(--stroke-x)',

    '::after': {
      content: '""',
      display: 'flex',
      size: '100%',

      bg: 'text',
    },

    py: '0.25em',
    boxSizing: 'content-box',

    '&:first-of-type': { pt: 0 },
    '&:last-of-type': { pb: 0 },

    transform: 'scaleX(0.6)',
    transformOrigin: 'right',
    '&[data-inview=true]': {
      transform: 'scaleX(0.999)',
    },
    '&[data-inview=false]:hover': {
      transform: 'scaleX(0.7)',
    },

    transition: 'transform 0.35s ease',
  },
}
