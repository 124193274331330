const common = {
  position: 'absolute',
  inset: 0,
}

export default {
  __default: {
    ...common,
    zIndex: '2',

    display: 'flex',
    height: '100vh',

    bg: 'background',
    transformOrigin: 'top',

    '.-line': {
      ...common,

      width: '100%',

      transform: 'scaleY(0)',
      transformOrigin: 'bottom',
    },

    '.has-scroll-smooth &': {},
  },
}
