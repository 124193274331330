import { transitions, transparentize } from 'polished'

const activeLabel = {
  '--label-position': '-165%',
  '--label-scale': '0.75',
  bg: 'background',
}

const common = {
  fontSize: 'var(--size-small)',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: 'currentColor',
}

const focus = {
  borderColor: 'primary',
  boxShadow: (t) => `0 0 0 2px ${transparentize(0.85, t.rawColors.primary)}`,
  outline: 'none',
}

const fieldStyles = {
  ...common,
  p: 'var(--button-py) var(--button-px)',
  borderRadius: 'var(--input-radius)',

  '&:focus': {
    ...focus,
    '&+label': activeLabel,
  },
  '[data-value=true] &+label': {
    ...activeLabel,
  },
}

export default {
  __default: {
    position: 'relative',
    display: 'grid',
    gridGap: 'var(--main-gap)',

    '--input-radius': '25px',

    '& > .overlay': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      p: 1,
      textAlign: 'center',

      position: 'absolute',
      inset: 0,
      zIndex: '1',

      visibility: 'hidden',
      transform: 'translateY(10px)',
      opacity: 0,
      ...transitions(['transform', 'opacity'], '0.5s ease 1s'),
    },
    '& > *:not(.overlay)': {
      ...transitions(['transform', 'opacity'], '0.5s ease 0.5s'),
    },

    '&[data-form-state] > .overlay': {
      visibility: 'visible',
      transform: 'translateY(0px)',
      opacity: 1,
    },

    '&[data-form-state] > *:not(.overlay)': {
      transform: 'translateY(5px)',
      opacity: 0,
    },
  },
  label: {
    '--label-padding': '3px',
    '--label-position': '-50%',
    '--label-scale': '1',

    fontSize: 'var(--size-small)',

    width: 'fit-content',
    alignItems: 'center',
    whiteSpace: 'nowrap',

    pointerEvents: 'none',
    position: 'absolute',
    top: '1.6em',
    left: 'calc(var(--button-px) - var(--label-padding))',
    px: 'var(--label-padding)',

    transform: 'translateY(var(--label-position)) scale(var(--label-scale))',
    transformOrigin: 'left',
    transition: 'transform 0.15s ease',
  },
  input: {
    ...fieldStyles,
  },
  autosubmit: {
    button: {
      fontSize: 'var(--size-small)',

      display: 'flex',
      justifyContent: 'flex-end',

      position: 'absolute',
      width: '3em',
      top: 0,
      bottom: 0,
      right: 'var(--button-px)',

      svg: {
        width: 'var(--icon-width)',
        maxWidth: 'var(--icon-width)',
      },
    },
  },
  select: {
    position: 'relative',

    select: { py: 'var(--button-py)' },

    '&[aria-expanded=true] > aside': {
      pointerEvents: 'none',
      ...focus,
    },

    '&[data-value=true] label': activeLabel,

    '@media (hover: hover)': {
      cursor: 'pointer',
      select: {
        pointerEvents: 'none',
      },
    },

    '> aside': {
      ...fieldStyles,
      p: '0 var(--button-px)',
      position: 'relative',
      width: '100%',

      variant: 'button.layout.withicon',
    },

    dropdown: {
      ...common,
      ...focus,
      width: '100%',
      borderRadius: 'var(--input-radius)',
      bg: 'background',
      overflow: 'hidden',

      position: 'absolute',
      top: 'calc(100% + 1em)',
      left: 0,
      zIndex: '10',

      display: 'none',
      '@media (hover: hover)': {
        '[aria-expanded=true] &': { display: 'block' },
      },

      li: {
        p: 'var(--button-py) var(--button-px)',

        cursor: 'pointer',

        borderWidth: 1,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderStyle: 'solid',
        borderColor: 'transparent',

        '&:hover, &[data-selected=true]': {
          bg: (t) => transparentize(0.75, t.rawColors.primary),
        },
      },

      'li+li': {
        borderTopColor: 'primary',
      },
    },
  },
  textarea: {
    ...fieldStyles,
    height: '7.5rem',
    resize: 'none',
  },
}
