import { Icon } from '@monobits/components'
import { Flex } from 'theme-ui'

const IconButton = ({ icon, ...props }) => (
  <Flex variant="button.variants.icon" {...props}>
    <Icon icon={icon} />
  </Flex>
)

export default IconButton
