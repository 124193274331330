import { useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useSetRecoilState } from 'recoil'
import { useMeasure } from '@react-hookz/web'

import Mouse from './Mouse'
import Touch from './Touch'
import { StyledHeader } from './Menu.styled'
import { headerMeasures } from '../../atoms'

const Menu = ({ sx, ...props }) => {
  const data = useStaticQuery(graphql`
    {
      site {
        metas: siteMetadata {
          contact {
            phone {
              tel
            }
            email {
              email
              headers {
                subject
                cc
              }
            }
          }
          locales {
            contact
          }
        }
      }
    }
  `)

  const setMeasures = useSetRecoilState(headerMeasures)
  const [measures = {}, ref] = useMeasure()

  useEffect(() => setMeasures(measures), [measures])

  return (
    <StyledHeader ref={ref} sx={{ variant: 'menu.header', ...sx }} {...props}>
      <div className="-touch">
        <Touch data={data.site.metas} />
      </div>
      <div className="-mouse">
        <Mouse data={data.site.metas} />
      </div>
    </StyledHeader>
  )
}

export default Menu
