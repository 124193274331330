import { toCSSVar as _ } from '@monobits/theme-core'

export default {
  styles: {
    '--speed': '0.35s',
    minWidth: 'var(--button-width)',
    fontSize: 'var(--size-small)',

    '::before': {
      border: '1px solid var(--stroke-color)',
      borderRadius: '200px',
    },
  },
  variants: {
    primary: {
      '--text': _`background`,
      '--text-hover': _`primary`,
      '--bg': _`primary`,
      '--bg-hover': _`background`,
      '--stroke-color': 'var(--bg)',
    },
    stroked: {
      '--text': _`text`,
      '--text-hover': _`background`,
      '--bg': 'transparent',
      '--bg-hover': _`primary`,
      '--stroke-color': 'var(--bg-hover)',
    },
    icon: {
      fontSize: 1,
      borderRadius: '50%',

      '--padding': '0.7em',

      color: 'background',
      bg: 'primary',

      size: '2.35em',
      p: 'var(--padding)',
    },
  },
  layout: {
    __default: {
      textAlign: 'center',
    },
    left: {
      textAlign: 'left',
      justifyContent: 'flex-start',
    },
    withicon: {
      display: 'grid',
      gridTemplateColumns: '1fr var(--icon-width)',
      textAlign: 'left',

      'svg, .icon': {
        position: 'absolute',
        gridColumn: '2 / 3',
      },
    },
  },
  sizes: {
    md: { p: 'var(--button-py) var(--button-px)' },
  },
  group: {
    '--template-columns': 'var(--button-group-columns)',
    '&.-fit': {
      '--template-columns': 'var(--button-group-columns-fit)',
    },
    '&.-semi': {
      '--template-columns': 'var(--button-group-columns-semi)',
    },
    gridTemplateColumns: 'var(--template-columns)',
    gridGap: 'var(--main-gap)',
  },
}
