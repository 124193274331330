import { atom } from 'recoil'

export const headerMeasures = atom({
  key: 'headerMeasures',
  default: {},
})

export const pageAnchors = atom({
  key: 'pageAnchors',
  default: [],
})

export const wholesaleState = atom({
  key: 'wholesaleState',
  default: {
    option: '',
    product: '',
  },
})
