export default {
  banner: {
    speed: -3,
    height: 75,
    __default: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
    },
  },
  indent: {
    __default: {
      display: 'inline-block',
      textIndent: ['1.15em', '1.75em'],
    },
  },
  link: {
    underlined: {
      fontSize: 1,

      '--gap': '0.12em',
      '--speed': '0.35s',
    },
  },
}
