import Obfuscate from 'react-obfuscate'
import parsePhoneNumber from 'libphonenumber-js'
import { Underlined } from '@monobits/components'

import icon from '../../../assets/svg/logo-long.svg'
import Logo from './Logo'
import ScrollNav from '../ScrollNav'

const Item = (props) => (
  <Underlined>
    <Obfuscate target="_blank" aria-label="Call us" {...props} />
  </Underlined>
)

const Mouse = ({ data, sx, ...props }) => {
  const {
    contact: { email, phone },
    locales: { contact },
  } = data ?? {}

  const parsePhone = parsePhoneNumber(phone.tel, 'CA').formatNational()

  return (
    <>
      <Logo icon={icon} />
      <nav sx={{ variant: 'menu.nav.mouse', ...sx }} {...props}>
        <Item {...phone} children={parsePhone} />
        <Item {...email} children={contact} />
        <ScrollNav />
      </nav>
    </>
  )
}

export default Mouse
