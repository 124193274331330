import { makeHighlight } from '@monobits/colors'

const opacity = 0.15

const colors = {
  light: '#ffffff',
  dark: '#2D2D2D',
  blue: '#2066A2',
}

export default {
  text: colors.light,
  background: colors.blue,
  primary: colors.light,
  highlight: makeHighlight(colors.light, opacity),
  ...colors,
  modes: {
    light: {
      text: colors.dark,
      background: colors.light,
      primary: colors.dark,
      highlight: makeHighlight(colors.dark, opacity),
    },
  },
}
