export default {
  useLocalStorage: false,
  useColorSchemeMediaQuery: false,

  useStickyHeader: true,
  useShyHeader: true,

  initialColorModeName: 'dark',

  colorTransitions: {
    duration: 0.35,
    ease: 'power1.inOut',
  },

  locomotive: {
    smooth: true,
    multiplier: 1,
    lerp: 0.15,
    scrollFromAnywhere: true,
    extends: {
      scrub: false,
      rootPercent: 4,
    },
  },
}
