import { createElement } from 'react'
import { useRecoilValue } from 'recoil'
import { useLocomotiveScroll } from '@monobits/locomotive-scroll'

import { headerMeasures } from '../atoms'

const ScrollTo = ({ anchor, offset = 0, delay, forwardedAs = 'button', onClick = () => {}, ...props }) => {
  const { scroll } = useLocomotiveScroll()
  const { height, y } = useRecoilValue(headerMeasures)

  const headerHeight = (height - offset - 1 + y * 2) * -1

  const handleClick = () => {
    onClick()
    if (!!anchor) {
      const run = () => scroll.scrollTo(`[data-handle=${anchor}]`, { offset: headerHeight })
      if (!delay) return run()
      return setTimeout(() => run(), delay)
    }
    return console.warn('ScrollTo component is missing a "anchor" prop.')
  }

  return createElement(forwardedAs, { onClick: handleClick, ...props })
}

export default ScrollTo
